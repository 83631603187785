import { section1 } from './section1'
// import { section2 } from './section2'
// import { section3 } from './section1'
// import { section4 } from './section4'

// const URL = "https://korero-media.s3.ap-southeast-2.amazonaws.com/audio-book-2/"

export const menuDataThree = [
    {
        id: 1,
        maori: "Koorerorero 1",
        eng: "Conversation 1",
        sub_menu: section1
    },
    // {
    //     id: 2,
    //     maori: "Ngaa Rongoaa",
    //     eng: "Traditional Medicines",
    //     sub_menu: section2
    // },
    // {
    //     id: 3,
    //     maori: "Te Mana o te Wahine",
    //     eng: "The Mana of Women",
    //     sub_menu: section3
    // },
    // {
    //     id: 4,
    //     maori: "Kai Hikareti",
    //     eng: "Smoking Cigarettes",
    //     sub_menu: section4
    // }
]